// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --clr-white: #ffffff;
  --clr-black: #000000;

  --border-loading-spinner: solid 5px var(--clr-white);
}`, "",{"version":3,"sources":["webpack://./src/styles/vars.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;;EAEpB,oDAAoD;AACtD","sourcesContent":[":root {\n  --clr-white: #ffffff;\n  --clr-black: #000000;\n\n  --border-loading-spinner: solid 5px var(--clr-white);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
