// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-container {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}

.language-container:hover, .language-container:focus-within {
  background-color: #666;
  color: #eee;
}

.language-container>label {
  z-index: 10;
  pointer-events: none;
}

select {
  position: absolute;
  right: 0;
  width: 100%;
  cursor: pointer;
  text-align: right;
  padding: 0.5rem;
}

select:hover, select:focus {
  background-color: #666;
  color: #eee;
}

button {
  border: 1px solid black;
  padding: 0.5rem 1rem;
}

button:hover, button:focus {
  background-color: #666;
  color: #eee
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  padding: 10px;
}

form>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border: 1px solid black;
  padding: 5px;
  width: 400px;
}

label {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/home/resumeBuilderForm/ResumeBuilderForm.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,mBAAmB;;EAEnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,QAAQ;EACR,uBAAuB;EACvB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".language-container {\n  position: relative;\n  display: flex;\n  justify-content: start;\n  align-items: center;\n}\n\n.language-container:hover, .language-container:focus-within {\n  background-color: #666;\n  color: #eee;\n}\n\n.language-container>label {\n  z-index: 10;\n  pointer-events: none;\n}\n\nselect {\n  position: absolute;\n  right: 0;\n  width: 100%;\n  cursor: pointer;\n  text-align: right;\n  padding: 0.5rem;\n}\n\nselect:hover, select:focus {\n  background-color: #666;\n  color: #eee;\n}\n\nbutton {\n  border: 1px solid black;\n  padding: 0.5rem 1rem;\n}\n\nbutton:hover, button:focus {\n  background-color: #666;\n  color: #eee\n}\n\nform {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n\n  padding: 10px;\n}\n\nform>div {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 5px;\n  border: 1px solid black;\n  padding: 5px;\n  width: 400px;\n}\n\nlabel {\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
