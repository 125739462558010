// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-page-wrapper {
  overflow: hidden;
}

.pdf-page-viewer {
  width: 100%;
  min-height: 100svh;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/pdf/PdfPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ","sourcesContent":[".pdf-page-wrapper {\n  overflow: hidden;\n}\n\n.pdf-page-viewer {\n  width: 100%;\n  min-height: 100svh;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
